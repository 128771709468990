import Link from 'next/link';
import { useRouter } from 'next/router';
import { useAppSelector } from '../../redux/hooks';

import {
  BetaTileContainer,
  NavigationItem,
  NavigationTile,
  NavigationTileAction,
  NavigationTileHeader,
  NavigationTileTitle,
  NewTileContainer,
  NoticePill,
  SubOption,
  SubOptionsContainer,
} from '../../user/components/SideNavigation/SideNavvigationStyles';
import { useNotificationsContext } from '../../user/components/UserLayout/UserLayout';

import { useCallback, useContext, useState } from 'react';
import { COLORS } from '../../constants/colors';
import { AuthContext } from '../../user/context/AuthContext';
import { MaskIcon } from '../Icons/Icons';

const SMDrawerContent = [
  {
    icon: '/icons/new-ticket-icon.svg',
    title: 'Tickets',
    route: '/user/selling-accounts/tickets',
  },
  {
    icon: '/icons/new-ticket-icon.svg',
    title: 'Ticket Logs',
    route: '/user/selling-accounts/ticket-logs',
  },
];

const drawerContent = [
  {
    icon: '/icons/solid-dashboard.svg',
    title: 'Dashboard',
    route: '/user/selling/dashboard',
  },
  {
    icon: '/icons/solid-selling.svg',
    title: 'Currently Selling',
    route: '/user/selling/currently-selling',
  },
  {
    icon: '/icons/bag-24.svg',
    title: 'Sell Item',
    route: '/user/selling/sell-item',
  },
  {
    icon: '/icons/speed-24.svg',
    title: 'Campaigns',
    route: '/user/selling/campaigns',
  },
  {
    icon: '/icons/seller-txn.svg',
    title: 'Transactions',
    route: '/user/selling/transactions',
  },
  {
    icon: '/icons/solid-withdraw.svg',
    title: 'Withdrawals',
    route: '/user/selling/withdrawals',
  },
  {
    icon: '/icons/solid-reports.svg',
    title: 'Reports',
    route: '/user/selling/reports',
  },
  {
    icon: '/icons/reviews-24.svg',
    title: 'Ratings & Reviews',
    route: '/user/selling/ratings',
  },
  {
    icon: '/icons/api-settings.svg',
    title: 'API Settings',
    route: '/user/selling/api-settings',
  },
  {
    icon: '/icons/declared-stock-icon.svg',
    title: 'Declared Stock',
    route: '/user/selling/declared-stock',
  },
  {
    icon: '/icons/new-ticket-icon.svg',
    title: 'Tickets',
    route: '/user/selling/tickets',
  },
  {
    icon: '/icons/new-ticket-icon.svg',
    title: 'Ticket Logs',
    route: '/user/selling/ticket-logs',
  },
  {
    icon: '/icons/notification_setting_re.svg',
    title: 'Inbox',
    route: '/user/selling/notifications',
  },
  {
    icon: '/icons/setting-24.svg',
    title: 'Surcharge Settings',
    route: '/user/selling/surcharge',
  },
  {
    icon: '/icons/campaign-settings.svg',
    title: 'Campaigns Settings',
    route: '/user/selling/campaign-settings',
  },
  {
    icon: '/icons/notification_setting_re.svg',
    title: 'Notification Settings',
    route: '/user/selling/notification-settings',
  },
];

interface Props {
  onClose: () => void;
}

export default function SellerDrawerContent({ onClose }: Props) {
  const router = useRouter();
  const { user } = useAppSelector((state) => state.user);
  const { storeUser } = useContext(AuthContext);
  const notificationsContext = useNotificationsContext();
  const [selectedHeaderOption, setSelectedHeaderOption] = useState<
    'user' | 'seller' | 'setting' | 'accounts' | ''
  >('');

  const [showStoreSubOption, setShowStoreSubOption] = useState(false);
  const [showProfileSubOption, setShowProfileSubOption] = useState(false);
  const [showSellerSettingsSubOption, setShowSellerSettingsSubOption] =
    useState(false);

  const handleStoreSubOptionClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      setShowStoreSubOption(!showStoreSubOption);
      setShowProfileSubOption(false);
      setShowSellerSettingsSubOption(false);
    },
    [showStoreSubOption]
  );
  const handleSellerSettingsSubOptionClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      setShowSellerSettingsSubOption(!showSellerSettingsSubOption);
      setShowProfileSubOption(false);
      setShowStoreSubOption(false);
    },
    [showSellerSettingsSubOption]
  );
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {user?.store_id && (
          <NavigationItem>
            <Link href={`${'/user/selling/dashboard'}`}>
              <a>
                <NavigationTile
                  active={
                    selectedHeaderOption === 'seller' || showStoreSubOption
                  }
                >
                  <NavigationTileHeader>
                    <MaskIcon
                      url="/icons/store.svg"
                      width="24px"
                      height="24px"
                      selected={true}
                      color={COLORS.white}
                    />

                    <NavigationTileTitle>My Store</NavigationTileTitle>
                  </NavigationTileHeader>
                  <NavigationTileAction>
                    {!isNaN(
                      Number(notificationsContext?.unreadNotificationsCount)
                    ) &&
                      notificationsContext!.unreadNotificationsCount > 0 &&
                      user?.store_id && (
                        <NoticePill
                          style={{
                            width: '8px',
                            height: '8px',
                            minHeight: '8px',
                            minWidth: '8px',
                            borderRadius: '50px',
                            padding: 0,
                          }}
                        />
                      )}
                    <MaskIcon
                      url="/icons/arrow-down-chevron-24.svg"
                      width="24px"
                      height="24px"
                      selected={true}
                      margin={'0 0 0 auto'}
                      color={COLORS.white}
                      onClick={(e: React.MouseEvent) =>
                        handleStoreSubOptionClick(e)
                      }
                      style={{
                        zIndex: 10,
                        transform: showStoreSubOption
                          ? 'rotate(180deg)'
                          : 'rotate(0)',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    />
                  </NavigationTileAction>
                </NavigationTile>
              </a>
            </Link>
            {showStoreSubOption && (
              <SubOptionsContainer>
                <Link href={'/user/selling/dashboard'} prefetch={false}>
                  <a>
                    <SubOption
                      active={router.pathname === '/user/selling/dashboard'}
                    >
                      Dashboard
                    </SubOption>
                  </a>
                </Link>
                <Link href={'/user/selling/currently-selling'} prefetch={false}>
                  <a>
                    <SubOption
                      active={
                        router.pathname === '/user/selling/currently-selling'
                      }
                    >
                      Currently Selling
                    </SubOption>
                  </a>
                </Link>
                <Link href={'/user/selling/transactions'} prefetch={false}>
                  <a>
                    <SubOption
                      active={router.pathname === '/user/selling/transactions'}
                    >
                      Transactions
                    </SubOption>
                  </a>
                </Link>
                {user?.storeDetails?.disabled === 1 ? null : (
                  <Link href={'/user/selling/sell-item'} prefetch={false}>
                    <a>
                      <SubOption
                        active={router.pathname === '/user/selling/sell-item'}
                      >
                        Sell Item
                      </SubOption>
                    </a>
                  </Link>
                )}

                {!!user?.storeDetails?.features_allowed?.campaigns && (
                  <Link href={'/user/selling/campaigns'} prefetch={false}>
                    <a>
                      <SubOption
                        active={router.pathname === '/user/selling/campaigns'}
                      >
                        Campaigns <NewTileContainer>New</NewTileContainer>
                      </SubOption>
                    </a>
                  </Link>
                )}
                <Link href={'/user/selling/withdrawals'} prefetch={false}>
                  <a>
                    <SubOption
                      active={router.pathname === '/user/selling/withdrawals'}
                    >
                      Withdrawals
                    </SubOption>
                  </a>
                </Link>
                <Link href={'/user/selling/reports'} prefetch={false}>
                  <a>
                    <SubOption
                      active={router.pathname === '/user/selling/reports'}
                    >
                      Reports
                    </SubOption>
                  </a>
                </Link>

                <Link href={'/user/selling/tickets'} prefetch={false}>
                  <a>
                    <SubOption
                      active={router.pathname.includes('/user/selling/tickets')}
                    >
                      Tickets <BetaTileContainer>Beta</BetaTileContainer>{' '}
                      {!isNaN(
                        Number(notificationsContext?.unResolvedTicketsCount)
                      ) &&
                        notificationsContext!.unResolvedTicketsCount > 0 && (
                          <NoticePill>
                            {notificationsContext!.unResolvedTicketsCount}
                          </NoticePill>
                        )}
                    </SubOption>
                  </a>
                </Link>
                {!!storeUser && storeUser === 'admin' && (
                  <Link href={'/user/selling/ticket-logs'} prefetch={false}>
                    <a>
                      <SubOption
                        active={router.pathname.includes(
                          '/user/selling/ticket-logs'
                        )}
                      >
                        Ticket Logs <BetaTileContainer>Beta</BetaTileContainer>{' '}
                      </SubOption>
                    </a>
                  </Link>
                )}

                <Link href={'/user/selling/ratings'} prefetch={false}>
                  <a>
                    <SubOption
                      active={router.pathname === '/user/selling/ratings'}
                    >
                      Ratings & Reviews
                    </SubOption>
                  </a>
                </Link>
                <Link href={'/user/selling/notifications'} prefetch={false}>
                  <a>
                    <SubOption
                      active={router.pathname === '/user/selling/notifications'}
                    >
                      Inbox{' '}
                      {!isNaN(
                        Number(notificationsContext?.unreadNotificationsCount)
                      ) &&
                        notificationsContext!.unreadNotificationsCount > 0 && (
                          <NoticePill>
                            {notificationsContext!.unreadNotificationsCount}
                          </NoticePill>
                        )}
                    </SubOption>
                  </a>
                </Link>
              </SubOptionsContainer>
            )}
          </NavigationItem>
        )}
        {user?.store_id && (
          <NavigationItem>
            <Link
              href={
                user.storeDetails?.features_allowed?.apiSelling
                  ? '/user/selling/api-settings'
                  : '/user/selling/notification-settings'
              }
            >
              <a>
                <NavigationTile
                  active={
                    selectedHeaderOption === 'setting' ||
                    showSellerSettingsSubOption
                  }
                >
                  <NavigationTileHeader>
                    <MaskIcon
                      url="/icons/settings.svg"
                      width="24px"
                      height="24px"
                      selected={true}
                      color={COLORS.white}
                    />

                    <NavigationTileTitle>Store Settings</NavigationTileTitle>
                  </NavigationTileHeader>
                  <NavigationTileAction>
                    <MaskIcon
                      url="/icons/arrow-down-chevron-24.svg"
                      width="24px"
                      height="24px"
                      selected={true}
                      margin={'0 0 0 auto'}
                      color={COLORS.white}
                      onClick={handleSellerSettingsSubOptionClick}
                      style={{
                        zIndex: 10,
                        transform: showSellerSettingsSubOption
                          ? 'rotate(180deg)'
                          : 'rotate(0)',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    />
                  </NavigationTileAction>
                </NavigationTile>
              </a>
            </Link>
            {showSellerSettingsSubOption && (
              <SubOptionsContainer>
                {user.storeDetails?.features_allowed?.apiSelling && (
                  <Link href={'/user/selling/api-settings'} prefetch={false}>
                    <a>
                      <SubOption
                        active={
                          router.pathname === '/user/selling/api-settings'
                        }
                      >
                        API Settings
                      </SubOption>
                    </a>
                  </Link>
                )}
                {!!user.storeDetails?.features_allowed?.declaredStock && (
                  <Link href={'/user/selling/declared-stock'} prefetch={false}>
                    <a>
                      <SubOption
                        active={
                          router.pathname === '/user/selling/declared-stock'
                        }
                      >
                        Declared Stock
                      </SubOption>
                    </a>
                  </Link>
                )}
                {!!user.storeDetails?.features_allowed?.vat && (
                  <Link href={'/user/selling/surcharge'} prefetch={false}>
                    <a>
                      <SubOption
                        active={router.pathname === '/user/selling/surcharge'}
                      >
                        Surcharge Settings
                      </SubOption>
                    </a>
                  </Link>
                )}
                {!!user.storeDetails?.features_allowed?.campaigns && (
                  <Link
                    href={'/user/selling/campaign-settings'}
                    prefetch={false}
                  >
                    <a>
                      <SubOption
                        active={
                          router.pathname === '/user/selling/campaign-settings'
                        }
                      >
                        Campaign Settings
                      </SubOption>
                    </a>
                  </Link>
                )}
                <Link
                  href={'/user/selling/notification-settings'}
                  prefetch={false}
                >
                  <a>
                    <SubOption
                      active={
                        router.pathname ===
                        '/user/selling/notification-settings'
                      }
                    >
                      Notification Settings
                    </SubOption>
                  </a>
                </Link>
              </SubOptionsContainer>
            )}
          </NavigationItem>
        )}
      </div>
    </>
    // <ContentWrapper fromMobNav>
    // </ContentWrapper>
  );
}
